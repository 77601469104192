import React from "react";
import "../../assests/globalcss/style.css";
import Navbar from "../../Navbar";
import Footer from "../../Footer";
import Elipse from "../../assests/globalimages/Ellipse 2.png";
import Sect_3_1 from "../../assests/globalimages/cleaning service-rafiki 4.png";
import Sect_3_2 from "../../assests/globalimages/cleaning service-bro 3.png";
import Sect_3_3 from "../../assests/globalimages/cleaning service-pana 5.png";
import Sect_3_4 from "../../assests/globalimages/cleaning service-amico 2.png";
import Sect_3_5 from "../../assests/globalimages/window washer-pana 2.png";
import Sect_3_6 from "../../assests/globalimages/sect_3_6.png";
import Sect_3_7 from "../../assests/globalimages/sect_3_7.png";
import Sect_3_8 from "../../assests/globalimages/sect_3_8.png";
import residential_1 from "../../assests/globalimages/resid_1.png";
import residential_2 from "../../assests/globalimages/resid_2.png";
import residential_3 from "../../assests/globalimages/resid_3.png";
import residential_4 from "../../assests/globalimages/resid_4.png";
import residential_5 from "../../assests/globalimages/resid_5.png";
import residential_6 from "../../assests/globalimages/resid_6.png";
import residential_7 from "../../assests/globalimages/resid_7.png";
import residential_8 from "../../assests/globalimages/resid_8.png";
import about from "../../assests/globalimages/about_us.png";
import Work1 from "../../assests/globalimages/work1.png";
import Work2 from "../../assests/globalimages/work2.png";
import Work3 from "../../assests/globalimages/work3.png";
import arrow from "../../assests/globalimages/work_arrow.png";
import Swiper from "../../Swiper";
const index = () => {
  return (
    <div>
      <Navbar />
      <section class="banner-section">
        <div class="container">
          <div class="row">
            <div class=" col-lg-7 col-md-12">
              {/* <!--Esse enim amet repu--> */}
              <div class="hme_bnr_text mt-3">
                <h1>
                  We Scrub, You <span>Relax</span>:
                  <br /> Your Cleaning
                  <br />
                  <span>Solution</span>!
                </h1>
                <p className="text-white mt-4">
                  We understand that people are different and so are their
                  cleaning requirements.
                </p>
                <div class="d-flex gap-2 mt-4">
                  <button class="  see_how_w">Learn More</button>
                  {/* <button class="  service_btn d-flex align-items-center justify-content-center px-4 py-3">
                    {" "}
                    Learn More
                  </button> */}
                </div>
              </div>
            </div>

            <div class="col-md-12 col-lg-7">
              <div class="hme_bnr_img">
                {/* <!--<img src="assets/images/bannerimg.png" class="img-fluid" alt="" />--> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="sect2">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-12">
              <h3 class="text-center mb-2">OUR 3 CORE VALUES</h3>
              <div class="d-flex align-items-center justify-content-center gap-3 wre_sect2">
                {/* <img src={Elipse} class="img-fluid" alt="" /> */}
                <h1>HONEST</h1>
                <img src={Elipse} class="img-fluid" alt="" />
                <h1>THOROUGH </h1>
                <img src={Elipse} class="img-fluid" alt="" />
                <h1>TIMELY</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="sect3">
        <div class="container">
          <div class="row justify-content-center">
            <div className="col-lg-10">
              <h1>
                Professional Cleaning <span>Services</span>
              </h1>
              <p>
                Efficient, reliable, and tailored cleaning solutions for homes
                and businesses. Our expert team ensures pristine spaces, using
                eco-friendly practices and top-notch equipment.
              </p>
              <h2>Commercial Cleaning Services</h2>
              <p>
                In Clany we use a wide variety of cleaning methods, chemicals,
                and equipment to facilitate and expedite the cleaning process.
              </p>
            </div>
          </div>
          <div class="row mt-5 row-gap-4">
            <div class="col-lg-3 col-md-4 col-sm-6">
              <div class="prof_crd">
                <img src={Sect_3_1} class="img-fluid" alt="" />
                <p>They empty all waste bins and replace all liners.</p>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
              <div class="prof_crd">
                <img src={Sect_3_2} class="img-fluid" alt="" />
                <p>Vacuum mats, carpets, and floors</p>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
              <div class="prof_crd">
                <img src={Sect_3_3} class="img-fluid" alt="" />
                <p>Clean automatic glass doors inside and out</p>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
              <div class="prof_crd">
                <img src={Sect_3_4} class="img-fluid" alt="" />
                <p>Dust and wipe all surfaces of tables.</p>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
              <div class="prof_crd">
                <img src={Sect_3_5} class="img-fluid" alt="" />
                <p>Warehouses, Airports Move in/out.</p>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
              <div class="prof_crd">
                <img src={Sect_3_6} class="img-fluid" alt="" />
                <p>Clean elevators and stairs.</p>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
              <div class="prof_crd">
                <img src={Sect_3_7} class="img-fluid" alt="" />
                <p>Maintaining commercial floors</p>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
              <div class="prof_crd">
                <img src={Sect_3_8} class="img-fluid" alt="" />
                <p> Cleaning around sensitive electronic equipment</p>
              </div>
            </div>
          </div>
          <a href="#" class="btn btn_lrn mt-4">
            Learn more
          </a>
        </div>
      </section>
      <section class="sect4">
        <div className="content">
          <div class="container">
            <h2 className="fw-bold">Residental Cleaning Services</h2>
            <p>
              The Duties and Responsibilities of a Residential Cleaner. If you
              reach out to a professional residential cleaning company, you can
              expect nothing less than a quality customer experience and quality
              service.
            </p>
            <div class="row row-gap-4">
              <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="prof_crd">
                  <img src={residential_1} class="img-fluid" alt="" />
                  <p>They help to empty trash.</p>
                </div>
              </div>
              <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="prof_crd">
                  <img src={residential_2} class="img-fluid" alt="" />
                  <p>Sweeping and mopping the floors.</p>
                </div>
              </div>
              <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="prof_crd">
                  <img src={residential_3} class="img-fluid" alt="" />
                  <p>Vacuum floors and carpets.</p>
                </div>
              </div>
              <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="prof_crd">
                  <img src={residential_4} class="img-fluid" alt="" />
                  <p>Cleaning light fixtures and door handles.</p>
                </div>
              </div>
              <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="prof_crd">
                  <img src={residential_5} class="img-fluid" alt="" />
                  <p>Dusting rooms, windows, and window sills.</p>
                </div>
              </div>
              <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="prof_crd">
                  <img src={residential_6} class="img-fluid" alt="" />
                  <p>Cleaning and disinfecting the toilet</p>
                </div>
              </div>
              <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="prof_crd">
                  <img src={residential_7} class="img-fluid" alt="" />
                  <p>Cleaning the sink, bathtubs, and showers</p>
                </div>
              </div>
              <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="prof_crd">
                  <img src={residential_8} class="img-fluid" alt="" />
                  <p>Wiping countertops & outside of the kitchen cabinets</p>
                </div>
              </div>
            </div>
            <a href="#" class="btn btn_lrn mt-4">
              Learn more
            </a>
          </div>
        </div>
      </section>
      <section className="book_now">
        <div className="container">
          <div className="row justify-content-center">
            <div class="col-lg-9">
              <div className="row justify-content-center align-items-center">
                <div class="col-lg-8 col-md-10 col-sm-12 ">
                  <h1>SCHEDULE YOUR HOME CLEANING TODAY</h1>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12 cen">
                  <button className="book_btn">Book Now</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about_us">
        <div class="container">
          <div className="row justify-content-between ">
            <div className="col-lg-7 col-md-12 col-sm-12">
              <div className="content_about">
                <p className="about mb-0">
                  <span className="fw-semibold">About Us</span>
                </p>
                <h1 className="fw-bold">
                  We Are A Highly Experienced
                  <span> Cleaning Service Company</span>
                </h1>
                <p>
                  We're your partners in maintaining a healthy and pristine
                  environment. With a dedicated team of professionals, we strive
                  for excellence in every cleaning task, ensuring your
                  satisfaction and peace of mind.
                </p>
                <ul>
                  <li>
                    <i class="fa-solid fa-check me-1"></i>100% Satisfaction
                    Guaranteed
                  </li>
                  <li>
                    <i class="fa-solid fa-check me-1"></i>Expert & Experienced
                    cleaning staff
                  </li>
                  <li>
                    <i class="fa-solid fa-check me-1"></i>Free lifetime support
                  </li>
                </ul>
                <a href="#" class="btn btn_lrn py-2 px-4 mb-3 ms-2">
                  Learn more
                </a>
              </div>
            </div>
            <div className="col-lg-5 col-md-12 col-sm-12 cen">
              <div className="d-flex justify-content-end">
                <img src={about} class="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="how_works">
        <div class="container">
          <h2 className="fw-bold">How it works</h2>
          <div class="row justify-content-center">
            <div class="col-lg-10">
              <p class="wp">
                We'll clean your home weekly, every two weeks, or every three
                weeks.All our plans include low-cost or FREE errand running
                services.
              </p>
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <div class="wrk_cards">
              <div class="wrk_crd_parnt">
                <div class="work_crd">
                  <div>
                    <img src={Work1} class="img-fluid" alt="" />
                  </div>
                  <div>
                    <h3>Get a price.</h3>
                    <p class="wpc">
                      Please tell us about yourself, your home, and your
                      cleaning needs.
                    </p>
                  </div>
                </div>
                <img src={arrow} class="img-fluid arw_img" alt="" />
                <div class="work_crd">
                  <div>
                    <img src={Work2} class="img-fluid" alt="" />
                  </div>
                  <div>
                    <h3>Book it.</h3>
                    <p class="wpc">
                      We'll make your house shine and run any errands you need.
                    </p>
                  </div>
                </div>
                <img src={arrow} class="img-fluid arw_img" alt="" />
                <div class="work_crd">
                  <div>
                    <img src={Work3} class="img-fluid" alt="" />
                  </div>
                  <div>
                    <h3>Breathe, smile, relax.</h3>
                    <p class="wpc">
                      The shopping are finished, and the house is tidy. It's
                      time to return home.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="service">
        <div class="container">
          <div class="row justify-content-center">
            <div className="col-lg-6 col-md-8 col-sm-12">
              <div class="service_crd">
                <h2 className="fw-bold">Home Cleaning & Maid Service</h2>
                <p>
                  Refocus your life by giving your house a cleaning from Dirty
                  Divas. Request quotes and book an online reservation in less
                  than 60 seconds.
                </p>
                <a href="#" class="btn btn_lrn">
                  Get a Quote
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="gallery">
        <div className="container">
          <div className="row">
            <div class="content_gallery">
              <h2 className="text-center fw-bold mb-1">Gallery</h2>
              <p className="text-center m-auto mb-4" style={{ width: "80%" }}>
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec
                odio. Quisque volutpat mattis eros. Nullam malesuada erat ut
                turpis. Suspendisse urna nibh, viverra non, semper suscipit,
                posuere.
              </p>
              <Swiper />
            </div>
          </div>
        </div>
      </section>
      <section className="book_now mt-5">
        <div className="container">
          <div className="row justify-content-center">
            <div class="col-lg-9">
              <div className="row justify-content-center align-items-center">
                <div class="col-lg-8 col-md-10 col-sm-12 ">
                  <h1>DON’T WAIT, BOOK YOUR HOME CLEANING TODAY</h1>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12 cen">
                  <button className="book_btn">Book Now</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default index;
