/* eslint-disable */
import React from "react";
import Index from "./Component/pages/Index";
const App = () => {
  return (
    <div>
      <Index />
    </div>
  );
};

export default App;
