import React, { Component } from "react";
import Slider from "react-slick";
import Galley1 from "../assests/globalimages/gallery1.png";
import Galley2 from "../assests/globalimages/gallery2.png";
import Galley3 from "../assests/globalimages/gallery3.png";
function Responsive() {
  var settings = {
    infinite: false,
    arrows: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    infinite: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          spaceBetween: 20,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div
      className="slider-container"
      style={{ marginLeft: "30px", marginRight: "30px" }}
    >
      <Slider {...settings}>
        <div>
          <img className="img-fluid" src={Galley1} alt="image" />
        </div>
        <div>
          <img src={Galley2} className="img-fluid" alt="image" />
        </div>
        <div>
          <img src={Galley3} className="img-fluid" alt="image" />
        </div>
      </Slider>
    </div>
  );
}

export default Responsive;
