import React from "react";
import "../assests/globalcss/style.css";
import logo from "../assests/globalimages/Logo.png";
const index = () => {
  return (
    <div>
      <section class="nav-bar">
        <div class="container">
          <nav class="navbar navbar-expand-lg fixed-top  bg-white   shadow">
            <div class="container">
              {/* <!--<a href="index"><img src="assets/images/logo.png" style="max-width: 147px" /></a>--> */}
              <a href="index">
                <img src={logo} style={{ maxWidth: "180px" }} />
              </a>
              <button
                class="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="navbar-toggler-icon"></span>
              </button>
              <div
                class="collapse navbar-collapse justify-content-end align-items-center"
                id="navbarNav"
              >
                <ul class="navbar-nav align-items-center gap-3 ms-4">
                  <li class="nav-item">
                    <a class="nav-link" aria-current="page">
                      Home
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link nav-btn_dii"
                      data-button=""
                      style={{ cursor: "pointer" }}
                    >
                      Our Service
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link nav-btn_dii"
                      data-button=""
                      style={{ cursor: "pointer" }}
                    >
                      About Us{" "}
                    </a>
                  </li>
                </ul>
                <ul className="btn_ul">
                  <li class="nav-item">
                    <a href="" class="" style={{ textDecoration: "none" }}>
                      <button className="btn px-3 py-2">Get a Quote</button>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </section>
    </div>
  );
};

export default index;
