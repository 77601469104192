import React, { useState, useEffect, useRef } from "react";
import logo from "../assests/globalimages/Logo.png";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assests/globalcss/style.css";
// import "bootstrap/dist/js/bootstrap.bundle.min.js";

const Index = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [companyName, setCompanyName] = useState("");
  const contactFormRef = useRef(null);
  const successPopupRef = useRef(null);
  useEffect(() => {
    window.$(contactFormRef.current).modal();
    window.$(successPopupRef.current).modal();
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    window.$.ajax({
      url: "https://fincen.ccalerc.com/api/umairEmail/sendEmailDivasApp",
      type: "POST",
      crossDomain: true,
      timeout: 0,
      cache: false,
      async: true,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
      },
      data: JSON.stringify({
        name: name,
        email: email,
        phone: phone,
        website: "Setczone.com",
      }),
      beforeSend: function () {
        window.$(".error").html("");
        window.$("#submitBtn").prop("disabled", true);
        window.$(".ThankYou").css("display", "none");
      },
      success: function (data) {
        document.getElementById("LeadForm").reset();
        window.$(successPopupRef.current).modal("show");
        window.$(contactFormRef.current).modal("hide");
      },
      error: function (er) {
        console.log("Status: " + er.status);
      },
      complete: function () {
        window.$("#submitBtn").prop("disabled", false);
      },
    });
  };

  return (
    <div>
      <section className="footer">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-5 col-md-8 col-sm-10 d-flex justify-content-center">
              <div className="footer-top text-center shadow">
                <h2 className="text-white">Get in Touch</h2>
                <button
                  className="btn btn-primary bb-footer shadow"
                  data-bs-toggle="modal"
                  data-bs-target="#contactfrm-popup"
                >
                  Contact Us
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="container d-flex flex-column align-items-center"
          style={{ marginTop: "100px" }}
        >
          <img
            src={logo}
            className="logo-nav text-center mb-3"
            alt=""
            srcSet=""
          />
          <p>
            <a className="text-black fw-bold" href="mailto:office ">
              info@dirtdivas.com
            </a>
          </p>
          <p>
            <a href=" " className="text-black fw-bold">
              Privacy Policy
            </a>
          </p>
        </div>
      </section>

      <div
        id="contactfrm-popup"
        className="modal fade contactfrm-popup-main contactfrm-popup--width"
        ref={contactFormRef}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <button
              type="button"
              className="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="fa-solid fa-xmark"></i>
            </button>

            <div className="modal-body">
              <div className="contactfrm_pop">
                <h2>Request more information</h2>

                <form
                  onSubmit={handleSubmit}
                  className="needs-validation"
                  id="LeadForm"
                  action="#"
                  role="form"
                  encType="multipart/form-data"
                  noValidate
                >
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="mb-2">
                          <label htmlFor="Name" className="form-label">
                            Name
                          </label>

                          <input
                            type="text"
                            className="form-control"
                            id="Name"
                            placeholder=""
                            name="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                          />

                          <div className="invalid-feedback nameError"></div>
                        </div>

                        <div className="mb-2">
                          <label htmlFor="Email" className="form-label">
                            Email
                          </label>

                          <input
                            type="email"
                            className="form-control"
                            id="Email"
                            placeholder=""
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                          />

                          <div className="invalid-feedback emailError"></div>
                        </div>

                        <div className="mb-2">
                          <label htmlFor="Phone" className="form-label">
                            Phone
                          </label>

                          <input
                            type="text"
                            className="form-control"
                            id="Phone"
                            placeholder=""
                            name="phone"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            required
                          />

                          <div className="invalid-feedback phoneError"></div>
                        </div>
                        <div className="mb-2">
                          <label htmlFor="Company" className="form-label">
                            Company Name
                          </label>

                          <input
                            type="text"
                            className="form-control"
                            id="Company"
                            placeholder=""
                            name="company_name"
                            value={companyName}
                            onChange={(e) => setCompanyName(e.target.value)}
                            required
                          />

                          <div className="invalid-feedback company_nameError"></div>
                        </div>
                      </div>
                      <div className="mb-3 d-flex justify-content-center">
                        <input
                          type="submit"
                          id="submitBtn"
                          className="btn btn-primary"
                          value="Submit"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="successPopup"
        className="modal fade"
        role="dialog"
        ref={successPopupRef}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header bg-success text-white">
              <h4 className="modal-title">
                <i className="fas fa-check-circle"></i> Success!
              </h4>
              <i
                className="fa-solid fa-xmark fs-3 text-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></i>
            </div>
            <div className="modal-body">
              <p>Your application has been submitted successfully!</p>
              <p>Our team will get back to you soon.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
